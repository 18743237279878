import React from 'react';

const ContactBlock = () => (
  <section className='contact-block support-block'>
    <div className='container'>
      <div className='contact-block__intro'>
        <h2 className='contact-block__subtitle'>Contact us</h2>
        <h3 className='contact-block__title'>Need some help?</h3>
        <div className='contact-block__description'>
          <p>We’re here to make sure you have a great experience with Mila. Reach out any time, day or night.</p>
        </div>
      </div>
      <div className='contact-block__detail'>
        <h3>Email</h3>
        <a href='mailto:support@milacares.com?subject=I have a question about Mila'>support@milacares.com</a>
        <address className='contact-block__address'>1 Belvedere Place Suite 200 Mill Valley, CA 94941</address>
      </div>
    </div>
  </section>
);

export default ContactBlock;
